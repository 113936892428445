<template>
  <div class="main index" v-if="!isLoading">
    <div
      class="columns
       is-mobile is-multiline"
    >
      <div class="column is-12">
        <h1 class="is-size-3"><b>Assessment Report</b></h1>
      </div>
      <div class="column is-10">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <h3 class="is-size-6">Assessment</h3>
            </li>
            <li>
              <h3 class="is-size-6 breadcrumb-item">Assessment Report</h3>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-12">
          <span class="columns">
            <div class="column is-1">Filter by</div>
            <div class="column is-3">
              <b-select
                placeholder="NIT, UID, Assessment Title, Name"
                v-model="filter"
                expanded
              >
                <option value="new_users.nit">NIT</option>
                <option value="new_users.uid">UID</option>
                <option value="new_assessments.title">
                  Assessment Title
                </option>
                <option value="new_users.name">Name</option>
              </b-select>
            </div>
            <div class="column is-narrow">Keyword</div>
            <div class="column is-3">
              <b-field>
                <b-input v-model="search" type="search"></b-input>
              </b-field>
            </div>
            <div class="column is-narrow">
              <b-button type="is-hcc" @click="searchReport(search)">
                Filter
              </b-button>
            </div>
          </span>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <b-table
            :data="getReports && getReports.data ? getReports.data : []"
            :current-page.sync="currentPage"
            paginated
            backend-pagination
            :loading="tableLoading"
            :total="getReports && getReports.total ? getReports.total : 0"
            :per-page="perPage"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @sort="onSort"
          >
            <b-table-column
              field="new_users.nit"
              label="NIT"
              sortable
              v-slot="props"
            >
              {{ props.row.nit }}
            </b-table-column>
            <b-table-column
              field="new_users.uid"
              label="UID"
              sortable
              v-slot="props"
            >
              {{ props.row.uid }}
            </b-table-column>
            <b-table-column
              field="new_assessments.title"
              label="Assesment Title"
              sortable
              v-slot="props"
            >
              <router-link :to="`/assessment-report/show/${props.row.id}`">
                {{ props.row.title }}
              </router-link>
            </b-table-column>
            <b-table-column
              field="new_users.name"
              label="Name"
              sortable
              v-slot="props"
            >
              {{ props.row.name }}
            </b-table-column>
            <b-table-column
              field="new_users.is_finish"
              label="Completed"
              sortable
              v-slot="props"
            >
              {{ props.row.isFinish ? 'Yes' : 'No' }}
            </b-table-column>
            <b-table-column
              field="new_users.reload_frequency"
              label="Freq Reload (times)"
              sortable
              width="150"
              v-slot="props"
            >
              {{ props.row.reloadFrequency }}
            </b-table-column>
            <b-table-column
              field="new_users.created_at"
              label="Submitted At"
              sortable
              v-slot="props"
            >
              {{ props.row.createdAt }}
            </b-table-column>
            <b-table-column
              field="action"
              label
              centered
              width="200"
              v-slot="props"
            >
              <b-button
                type="icon-button"
                icon-left="paper-plane"
                @click="
                  sendReport(
                    props.row.nit,
                    props.row.name,
                    props.row.email,
                    props.row.cid,
                    props.row.uid,
                    props.row.newAssessmentId,
                    props.row.tesId,
                    props.row.appUrl
                  )
                "
              ></b-button>
              <b-button
                type="icon-button"
                icon-left="eye"
                @click="detailReport(props.row.id)"
              ></b-button>
              <b-button
                type="icon-button"
                icon-left="download"
                @click="
                  downloadExcel2(
                    props.row.nit,
                    props.row.name,
                    props.row.email,
                    props.row.cid,
                    props.row.uid,
                    props.row.newAssessmentId,
                    props.row.tesId,
                    props.row.appUrl,
                    3
                  )
                "
              ></b-button>
            </b-table-column>
            <template slot="bottom-left">
              {{ getReports && getReports.from ? getReports.from : 0 }}
              -
              {{ getReports && getReports.to ? getReports.to : 0 }}
              from
              {{ getReports && getReports.total ? getReports.total : 0 }}
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Data not found.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      isFullPage: true,
      isLoading: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortOrder: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      activeTab: 0,
      sortField: 'id',
      sortOrder: 'desc',
      page: 1,
      filter: null,
      search: null,
      tableLoading: false,
    }
  },
  mounted() {
    this.fetchData(this.perPage, this.currentPage)
  },
  watch: {
    // search: function(val) {
    //   this.searchReport(val);
    // }
  },
  methods: {
    detailReport(id) {
      this.$router.push({ path: `/assessment-report/show/${id}` })
    },
    searchReport(value) {
      if (value) {
        this.search = value
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search,
          this.filter
        )
      } else {
        this.search = null
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search,
          this.filter
        )
      }
    },
    async exportPDF() {
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el,
      })
      await this.$store.dispatch('exportPDF')
      loadingComponent.close()
    },
    async exportCSV() {
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el,
      })
      await this.$store.dispatch('exportCSV')
      loadingComponent.close()
    },
    importExcel() {
      this.$store
        .dispatch('importExcel', this.file)
        .then(() => {
          this.success('Successfully import excel')
        })
        .catch(() => {
          this.danger('Failed to import excel')
        })
    },
    exportExcel() {
      this.$store.dispatch('exportExcel')
    },
    async fetchData(perPage, page, sortField, sortOrder, search, filter) {
      this.tableLoading = true
      await this.$store.dispatch('getNewReports', {
        perPage,
        page,
        sortField,
        sortOrder,
        search,
        filter,
      })
      this.tableLoading = false
    },
    onPageChange(page) {
      this.currentPage = page
      this.page = page
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.filter
      )
    },
    sendReport(nit, nama, email, cid, uid, assessmentId, idTes, appUrl) {
      this.$store
        .dispatch('sendToCapture', {
          nit: nit,
          nama: nama,
          email: email,
          cid: cid,
          uid: uid,
          assessment_id: assessmentId,
          id_tes: idTes,
          app_url: appUrl,
        })
        .then(() => {
          this.success('Data berhasil dikirimkan')
        })
        .catch(() => {
          this.danger('Data gagal dikirim')
        })
    },
    downloadExcel2(
      nit,
      nama,
      email,
      cid,
      uid,
      assessmentId,
      idTes,
      appUrl,
      excelId
    ) {
      this.$store
        .dispatch('downloadNewExcel2', {
          nit: nit,
          nama: nama,
          email: email,
          cid: cid,
          uid: uid,
          assessment_id: assessmentId,
          id_tes: idTes,
          app_url: appUrl,
          excelId: excelId,
        })
        .then(() => {
          this.success('Data berhasil didownload')
        })
        .catch(() => {
          this.danger('Data gagal didownload')
        })
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.filter
      )
    },

    // For success alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
  computed: {
    ...mapGetters({
      getReports: 'getNewReports',
    }),
  },
}
</script>

<style lang="scss">
.btn-import {
  width: 130px;
}
.space {
  margin-right: 10px;
}
.pdf {
  color: #ff0000;
}
.button-action {
  border: none !important;
  color: #00a3a3;
}
</style>
